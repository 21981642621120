import BaseRepository from "@/repositories/repositoryBase";

export class EpicRepository extends BaseRepository {
    constructor() {
        super();
    }

    async getEpicData(lookupCode, requester){
        let params = {
            LookupCode: lookupCode,
            Requester: requester
        }

        return await super.postWithBody('/api/epic/getepicsnapshot', params);
    }
}