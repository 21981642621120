<template>
    <header class="header">
        <img alt="rp" src="@/assets/reliance-logo-white.svg" />
        <div class="nav">
            <Button @click="processClick()">{{ this.buttonContent }}</Button>
        </div>
    </header>
</template>

<script>
import { PublicClientApplication } from '@azure/msal-browser';

export default {
    name: 'AppHeader',
    data() {
        return {
            buttonContent: "Sign In"
        };
    },
    async created() {
        this.$msalInstance = new PublicClientApplication(this.$store.state.msalConfig);
        this.$emitter.on('login', (account) => {
            this.buttonContent = "Welcome, " + account.name;
        });
        this.$emitter.on('logout', () => {
            this.buttonContent = "Sign In";
        });
    },
    async mounted() {
        await this.$msalInstance.initialize();
        const accounts = this.$msalInstance.getAllAccounts();
        if (accounts.length == 0) {
            return;
        }

        this.$store.account = accounts[0];

        //get the access token for the logged in user
        var tokenRequest = {
            scopes: ["user.read"],
            forceRefresh: true,
            account: this.$store.account
        };

        try {
            await this.$msalInstance.acquireTokenSilent(tokenRequest)
                .then(response => {
                    this.$store.commit("setAccessToken", response.accessToken);
                })

            this.$emitter.emit('login', this.$store.account);
        }
        catch (err) {
            alert(err);
            await this.silentSignOut();
        }
    },
    methods: {
        async processClick() {
            if (this.$store.account != undefined) {
                await this.signOut();
            }
            else {
                await this.signIn();
            }
        },
        async signIn() {
            await this.$msalInstance.handleRedirectPromise();

            await this.$msalInstance
                .loginPopup({})
                .then(() => {
                    const myAccounts = this.$msalInstance.getAllAccounts();
                    this.$store.account = myAccounts[0];
                    this.buttonContent = "Welcome, " + this.$store.account.name;
                })
                .catch(error => {
                    console.error(`error during authentication: ${error}`);
                });

            //get the access token for the logged in user
            var tokenRequest = {
                scopes: ["user.read"],
                forceRefresh: true,
                account: this.$store.account
            };

            await this.$msalInstance.acquireTokenSilent(tokenRequest)
                .then(response => {
                    this.$store.commit("setAccessToken", response.accessToken);
                })

            this.$emitter.emit('login', this.$store.account);
        },
        async signOut() {
            await this.$msalInstance
                .logout({})
                .then(() => {
                    this.$store.commit("setAccount", undefined);
                    this.$store.dispatch("signout");
                    this.$emitter.emit('logout', 'logging out');
                })
                .catch(error => {
                    console.error(error);
                });
        },
        async silentSignOut() {
            if (!this.$store.account) {
                const myAccounts = this.$msalInstance.getAllAccounts();
                this.$store.account = myAccounts[0];
                await this.$msalInstance.logoutRedirect({ account: this.$store.account });
                this.$store.commit("setAccount", undefined);
                this.$store.dispatch("signout");
                this.$emitter.emit('logout', 'logging out');
            }
        }
    }
}
</script>

<style scoped>
.p-button {
    background: #073C3E;
    border-color: #073C3E;
    color: #FFFFFF;
}
</style>