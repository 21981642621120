<template>
    <Button class="sn-button" label="Sticky Notes" @click="visible = true" />
    <Dialog v-model:visible="this.visible" modal header="Sticky Notes">
        <Card class="card" v-for="stickyNote in this.stickyNotes" v-bind:key="stickyNote.uniqStickyNote">
            <template #content>
                <span>{{ stickyNote.note }}</span>
            </template>
            <template #footer>
                <span class="note">Created On: {{ stickyNote.insertedDate }}</span>
                <br/>
                <span class="note">Updated On: {{ stickyNote.updatedDate }}</span>
            </template>
        </Card>
    </Dialog>
</template>

<script>
export default {
    name: 'StickyNotes',
    props: {
        stickyNotes: Array
    },
    data() {
        return {
            visible: false
        }
    }
}
</script>

<style scoped>
.card {
    width: 25rem;
    margin: 5px;
    background-color: lightyellow; 
    overflow: hidden
}

.note {
    font-size: x-small;
}

.sn-button {
    border-color: yellow;
    background-color: yellow !important;
    color: black;
}
</style>
