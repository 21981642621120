<template>
    <div class="main-section">
        <div class="section">
            <span class="header-space section-header left">Equipment</span>
            <DataTable v-if="this.equipment" :value="this.equipment" paginator :rows="10"
                :rowsPerPageOptions="[5, 10, 20, 50]" removableSort stripedRows>
                <template #header>
                    <div style="text-align: left">
                        <Button icon="pi pi-external-link" label="Export" @click="this.export($event)" />
                    </div>
                </template>
                <Column field="vehicleNumber" header="Vehicle Number" sortable />
                <Column field="vehicleYear" header="Vehicle Year" sortable />
                <Column field="make" header="Make" sortable />
                <Column field="vin" header="VIN" sortable />
                <Column field="type" header="Type" sortable />
                <Column field="vehicleType" header="Vehicle Type" sortable />
                <Column field="state" header="State" sortable/>
                <Column field="costNew" header="Cost New" sortable/>
            </DataTable>
        </div>
    </div>
</template>

<script>
import writeXlsxFile from 'write-excel-file';

export default {
    name: 'Equipment',
    props: {
        equipment: Array
    },
    methods: {
        async export() {
            var rows = new Array();

            var headerRow = [
                {
                    value: 'VEHICLE #'
                },
                {
                    value: 'YEAR'
                },
                {
                    value: 'MAKE'
                },
                {
                    value: 'VIN'
                },
                {
                    value: 'TYPE'
                },
                {
                    value: 'VEH. TYPE'
                },
                {
                    value: 'STATE'
                },
                {
                    value: 'COST NEW'
                }
            ]

            this.equipment.forEach(element => {
                var row = [
                    {
                        type: Number,
                        value: element.vehicleNumber
                    },
                    {
                        type: Number,
                        value: element.vehicleYear
                    },
                    {
                        type: String,
                        value: element.make
                    },
                    {
                        type: String,
                        value: element.vin
                    },
                    {
                        type: String,
                        value: element.type
                    },
                    {
                        type: String,
                        value: element.vehicleType
                    },
                    {
                        type: String,
                        value: element.state
                    },
                    {
                        type: Number,
                        value: element.costNew
                    }
                ]

                rows.push(row);
            });

            var data = [
                headerRow
            ]

            rows.forEach(element => {
                data.push(element);
            })

            await writeXlsxFile(data,
                {
                    fileName: "equipment.xlsx"
                })
        }
    }
}
</script>