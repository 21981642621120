<template>
    <div class="main-section">
        <div class="section">
            <h3 class="left">Radius</h3>
            <div class="label-text-group">
                <label class="label"><b>&lt;= 50 Miles:</b></label>
                <label>{{ this.convertToPercentage(this.radius.fifty) }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>50 and 200 Miles:</b></label>
                <label>{{ this.convertToPercentage(this.radius.fiftyTwoHundred) }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>200 and 500 Miles:</b></label>
                <label>{{ this.convertToPercentage(this.radius.twoHundredFiveHundred) }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>> 500 Miles:</b></label>
                <label>{{ this.convertToPercentage(this.radius.fiveHundred) }}</label>
            </div>
            <h3 class="left">Commodities</h3>
            <DataTable v-if="this.commodities" :value="this.commodities" paginator :rows="5"
                :rowsPerPageOptions="[5, 10, 20, 50]" removableSort stripedRows>
                <Column field="description" header="Description" sortable />
                <Column field="percentageGrossRevenue" header="% Gross Revenue" sortable>
                    <template #body="data">
                        <span>{{ this.convertToPercentage(data.data.percentageGrossRevenue) }}</span>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Commodities',
    props: {
        commodities: Array,
        radius: Object
    },
    methods: {
        convertToPercentage(decimal){
            return (Math.round(decimal * 100, 0)).toString() + '%';
        }
    }
}
</script>