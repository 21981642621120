<template>
    <div class="main-section">
        <div class="section">
            <span class="header-space section-header left">Drivers</span>
            <DataTable v-if="this.drivers" :value="this.drivers" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]"
                removableSort stripedRows>
                <template #header>
                    <div style="text-align: left">
                        <Button icon="pi pi-external-link" label="Export" @click="this.export($event)" />
                    </div>
                </template>
                <Column field="name" header="Name" sortable />
                <Column field="state" header="State" sortable />
                <Column field="licenseNumber" header="License #" sortable />
                <Column field="birthDate" header="Birth Date" sortable />
                <Column field="hireDate" header="Hire Date" sortable />
                <Column field="yearsExperience" header="Years Experience" sortable />
            </DataTable>
        </div>
    </div>
</template>

<script>
import writeXlsxFile from 'write-excel-file';

export default {
    name: 'Drivers',
    props: {
        drivers: Array
    },
    methods: {
        async export(){
            var rows = new Array();

            var headerRow = [
                {
                    value: 'NAME'
                },
                {
                    value: 'STATE'
                },
                {
                    value: 'LICENSE #'
                },
                {
                    value: 'BIRTHDAY'
                },
                {
                    value: 'DOH'
                },
                {
                    value: 'YOE'
                }
            ]

            this.drivers.forEach(element => {
                console.log(element);
                var row = [
                    {
                        type: String,
                        value: element.name
                    },
                    {
                        type: String,
                        value: element.state
                    },
                    {
                        type: String,
                        value: element.licenseNumber
                    },
                    {
                        type: String,
                        value: element.birthDate
                    },
                    {
                        type: String,
                        value: element.hireDate
                    },
                    {
                        type: Number,
                        value: element.yearsExperience
                    }
                ]

                rows.push(row);
            });

            var data = [
                headerRow
            ]

            rows.forEach(element => {
                data.push(element);
            })

            await writeXlsxFile(data,
            {
                fileName: "drivers.xlsx"
            })
        }
    }
}
</script>