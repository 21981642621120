<template>
    <div id="error" class="error">
        <span>{{ this.errorMessage }}</span>
    </div>
</template>

<script>
export default {
    name: 'Error',
    props: {
        errorMessage: String
    },
}
</script>

<style scoped>
.error {
    margin-top: 75px;
    color: red;
    font-size: medium;
}
</style>