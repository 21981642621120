<template>
    <div class="main-section">
        <div class="section">
            <span class="header-space section-header left">Additional Coverage</span>
            <DataTable v-if="this.additionalCoverage" :value="this.additionalCoverage" paginator :rows="10"
                :rowsPerPageOptions="[5, 10, 20, 50]" removableSort stripedRows>
                <Column field="lineType" header="Line Type" sortable />
                <Column field="description" header="Description" sortable />
                <Column field="deductible" header="Deductible" sortable />
                <Column field="limit" header="Limit" sortable />
            </DataTable>
        </div>
        <div class="section">
            <span class="header-space section-header left">Additional Insured</span>
            <DataTable v-if="this.additionalInsured" :value="this.additionalInsured" paginator :rows="10"
                :rowsPerPageOptions="[5, 10, 20, 50]" removableSort stripedRows>
                <Column field="description" header="Description" sortable />
                <Column field="address" header="Address" sortable />
                <Column field="city" header="City" sortable />
                <Column field="state" header="State" sortable />
            </DataTable>
        </div>
    </div>
    <div class="main-section">
        <div class="section">
            <span class="header-space section-header left">Physical Coverage</span>
            <DataTable v-if="this.physicalCoverage" :value="this.physicalCoverage" paginator :rows="10"
                :rowsPerPageOptions="[5, 10, 20, 50]" removableSort stripedRows>
                <Column field="lineType" header="Line Type" sortable />
                <Column field="policyNumber" header="Policy #" sortable />
                <Column field="compDeductible" header="Comp. Deductible" sortable />
                <Column field="collDeductible" header="Coll. Deductible" sortable />
            </DataTable>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdditionalCoverages',
    props: {
        additionalCoverage: Array,
        physicalCoverage: Array,
        additionalInsured: Array
    }
}
</script>