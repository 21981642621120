<template>
    <h3 class="left">Company Info</h3>
    <div class="main-section">
        <div class="section">
            <div class="label-text-group">
                <label class="label"><b>Epic Lookup Code:</b></label>
                <label>{{ this.clientInfo?.lookupCode }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>DOT:</b></label>
                <label>{{ this.clientInfo?.dot }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>Docket:</b></label>
                <label>{{ this.clientInfo?.mc }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>FEIN:</b></label>
                <label>{{ this.clientInfo?.fein }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>Operation Type:</b></label>
                <label>{{ this.operationsSafety?.operationType }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>Authority:</b></label>
                <label>{{ this.operationsSafety?.authority }}</label>
            </div>
            <div class="label-text-group space">
                <label class="label"><b>Years In Business:</b></label>
                <label>{{ this.operationsSafety?.yib }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>Contact Name:</b></label>
                <label>{{ this.clientInfo?.contactName }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>Phone:</b></label>
                <label>{{ this.clientInfo?.phoneNumber }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>Email:</b></label>
                <label>{{ this.clientInfo?.email }}</label>
            </div>
        </div>
        <div class="section">
            <div class="label-text-group">
                <label class="label"><b>Physical Address:</b></label>
                <label>{{ this.clientInfo?.physicalAddress }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>Physical City:</b></label>
                <label>{{ this.clientInfo?.physicalCity }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>Physical State:</b></label>
                <label>{{ this.clientInfo?.physicalState }}</label>
            </div>
            <div class="label-text-group space">
                <label class="label"><b>Physical Zip Code:</b></label>
                <label>{{ this.clientInfo?.physicalPostalCode }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>Mailing Address:</b></label>
                <label>{{ this.clientInfo?.mailingAddress }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>Mailing City:</b></label>
                <label>{{ this.clientInfo?.mailingCity }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>Mailing State:</b></label>
                <label>{{ this.clientInfo?.mailingState }}</label>
            </div>
            <div class="label-text-group space">
                <label class="label"><b>Mailing Zip Code:</b></label>
                <label>{{ this.clientInfo?.mailingPostalCode }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>Producer:</b></label>
                <label>{{ this.clientInfo?.producer ?? 'N/A'}}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>Co-Producer:</b></label>
                <label>{{ this.clientInfo?.coproducer ?? 'N/A' }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>Marketer:</b></label>
                <label>{{ this.clientInfo?.marketer ?? 'N/A' }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>Account Manager:</b></label>
                <label>{{ this.clientInfo?.accountManager ?? 'N/A'}}</label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ClientInfo',
    props: {
        clientInfo: Object,
        operationsSafety: Object
    }
}
</script>