<template>
    <div class="main-section">
        <div class="section">
            <h3 class="left">Safety</h3>
            <div class="label-text-group">
                <label class="label"><b>Safety Date:</b></label>
                <label>{{ this.safety?.safetyDate }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>ISS:</b></label>
                <label v-if="this.safety?.iss == 'Red'" class="red">{{ this.safety?.iss }}</label>
                <label v-else-if="this.safety?.iss == 'Yellow'" class="yellow">{{ this.safety?.iss }}</label>
                <label v-else-if="this.safety?.iss == 'Green'" class="green">{{ this.safety?.iss }}</label>
                <label v-else>{{ this.safety?.iss }}</label>
            </div> 
            <div class="label-text-group">
                <label class="label"><b>Safety Rating:</b></label>
                <label>{{ this.safety?.safetyRating }}</label>
            </div> 
            <div class="label-text-group">
                <label class="label"><b>Unsafe Driving:</b></label>
                <label>{{ this.safety?.unsafeDrivingAlert }}</label>
            </div> 
            <div class="label-text-group">
                <label class="label"><b>Hours Compliance:</b></label>
                <label>{{ this.safety?.hosComplianceAlert }}</label>
            </div>
            <div class="label-text-group">
                <label class="label"><b>Driver Fitness:</b></label>
                <label>{{ this.safety?.driverFitnessAlert }}</label>
            </div> 
            <div class="label-text-group">
                <label class="label"><b>Controlled Substance:</b></label>
                <label>{{ this.safety?.controlledSubstanceAlert }}</label>
            </div> 
            <div class="label-text-group">
                <label class="label"><b>Vehicle Maintenance:</b></label>
                <label>{{ this.safety?.vehicleMaintenanceAlert }}</label>
            </div> 
            <div class="label-text-group">
                <label class="label"><b>Hazmat:</b></label>
                <label>{{ this.safety?.hazmatAlert }}</label>
            </div> 
            <div class="label-text-group">
                <label class="label"><b>Crash:</b></label>
                <label>{{ this.safety?.crashAlert }}</label>
            </div> 
        </div>
    </div>
</template>

<script>
export default {
    name: "Safety",
    props: {
        safety: Object
    }
}
</script>