import { BrowserCacheLocation } from "@azure/msal-browser";
import { createStore } from "vuex";

const store = createStore({
    state() {
      return {
        msalConfig: {
          auth: {
            clientId: "3c86b34f-e0b6-4d79-be92-f668a9016b8a",
            redirectUri: "",
            postLogoutRedirectUri: process.env.VUE_APP_REDIRECT_URI,
            authority:
              "https://login.microsoftonline.com/a3426403-026c-4ede-a083-96586ce500c0",
          },
          cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage
          },
        },
        accessToken: "",
        account: "",
        apiToken: "",
        apiTokenExp: "",
        packageVersion: process.env.PACKAGE_VERSION || "0",
      };
    },
    mutations: {
      setAccessToken(state, token) {
        state.accessToken = token;
      },
      setAccount(state, account) {
        state.account = account;
      },
      setApiToken(state, apiToken) {
        state.apiToken = apiToken;
      },
      setApiTokenExp(state, apiTokenExp) {
        state.apiTokenExp = apiTokenExp;
      }
    },
    getters: {
      account: (state) => {
        return state.account;
      },
      accessToken: (state) => {
        return state.accessToken;
      },
      apiToken: (state) => {
        return state.apiToken;
      },
      apiTokenExp: (state) => {
        return state.apiTokenExp;
      },
      appVersion: (state) => {
        return state.packageVersion;
      }
    },
    actions: {
      signout({state}){
        console.log("signout called in store");
        state.accessToken = "";
        state.account = "";
        state.apiToken = "";
        state.apiTokenExp = "";
      }
    }
  });
  
  export default store;